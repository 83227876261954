import { Container, Grid } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Contenedor from "components/Contenedor";
import TextSection from "components/TextSection";
import Confianza from "assets/images/PURP_WEB/2.NOSOTROS/CHECK_CAFE.png";
import cer1 from "assets/images/PURP_WEB/2.NOSOTROS/4.png";
import Non from "assets/images/PURP_WEB/2.NOSOTROS/2.png";
import esr from "assets/images/PURP_WEB/2.NOSOTROS/3.png";
import KOSHER from "assets/images/PURP_WEB/2.NOSOTROS/5.png";
import IMSS_CAT from "assets/images/PURP_WEB/2.NOSOTROS/6.png";
import PNA from "assets/images/PURP_WEB/2.NOSOTROS/8.png";
import IMSS_ELSSA from "assets/images/PURP_WEB/2.NOSOTROS/7.png";
import Icontext from "../../../../pages/components/IconText";

const theme = createTheme({
  typography: {
    h5: {
      fontSize: "1.2rem",
      fontWeight: "bold",
      color: "#5B524D",
    },
    body1: {
      fontSize: "1rem",
      color: "#5B524D",
    },
  },
});

const valores = ["相信", "創新", "熱情", "積極主動", "責任"];

const certificaciones = [cer1, esr, Non, KOSHER, IMSS_CAT, IMSS_ELSSA, PNA];

function Presentacion() {
  return (
    <ThemeProvider theme={theme}>
      <Contenedor mt={{ xs: 12, lg: 18 }} mb={0}>
        <Container>
          <Grid container spacing={10} justifyContent="space-between" alignItems="flex-start">
            {/* Misión, Visión, Propósito - Alineado a la izquierda */}
            <Grid item xs={12} sm={12} md={5} lg={6}>
              <TextSection variant="h5" sx={{ color: "#5B524D" }}>
                使命
              </TextSection>
              <TextSection sx={{ color: "#5B524D" }} variant="body1" textAlign="justify">
                提供農業解決方案和投入品、市場糧食和優質服務；產生信任。
              </TextSection>

              <TextSection sx={{ color: "#5B524D" }} variant="h5" mt={3}>
                想像
              </TextSection>
              <TextSection sx={{ color: "#5B524D" }} variant="body1" textAlign="justify">
                成為一家擁有多元化業務、實力雄厚、有償付能力的世界級企業，使我們能夠鞏固自己作為專業精神和認證質量的標桿，為該地區的經濟發展做出貢獻。
              </TextSection>

              <TextSection sx={{ color: "#5B524D" }} variant="h5" mt={3}>
                目的
              </TextSection>
              <TextSection sx={{ color: "#5B524D" }} variant="body1" textAlign="justify">
                與土地相連，養活世界，創造價值和信任。
              </TextSection>
            </Grid>

            {/* Valores - Alineado al centro */}
            <Grid item xs={12} sm={12} md={3} lg={2} textAlign="center">
              <TextSection variant="h5" sx={{ color: "#5B524D" }}>
                價值觀
              </TextSection>
              <Grid container spacing={1} justifyContent="center" mt={2}>
                {valores.map((valor, thing) => (
                  <Grid key={thing.id} item xs={5} sm={4} md={12} lg={12}>
                    <Icontext color="#5B524D" imagen={Confianza} maxWidth="1.5rem" Texto={valor} />
                  </Grid>
                ))}
              </Grid>
            </Grid>

            {/* Certificaciones - Alineado a la derecha */}
            <Grid item xs={12} sm={12} md={4} lg={4} textAlign="center">
              <TextSection variant="h5" sx={{ color: "#5B524D" }}>
                認證證書
              </TextSection>
              <Grid container spacing={2} justifyContent="center" mt={1}>
                {certificaciones.map((cert, thing) => (
                  <Grid key={thing.id} item xs={6} sm={4} md={6} lg={6}>
                    <Contenedor component="img" src={cert} maxWidth="12rem" />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Contenedor>
    </ThemeProvider>
  );
}

export default Presentacion;
