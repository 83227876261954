import { Container, Grid } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Contenedor from "components/Contenedor";
import TextSection from "components/TextSection";
import Confianza from "assets/images/PURP_WEB/2.NOSOTROS/CHECK_CAFE.png";
import cer1 from "assets/images/PURP_WEB/2.NOSOTROS/4.png";
import Non from "assets/images/PURP_WEB/2.NOSOTROS/2.png";
import esr from "assets/images/PURP_WEB/2.NOSOTROS/3.png";
import KOSHER from "assets/images/PURP_WEB/2.NOSOTROS/5.png";
import IMSS_CAT from "assets/images/PURP_WEB/2.NOSOTROS/6.png";
import PNA from "assets/images/PURP_WEB/2.NOSOTROS/8.png";
import IMSS_ELSSA from "assets/images/PURP_WEB/2.NOSOTROS/7.png";
import Icontext from "../../../components/IconText/index";

const theme = createTheme({
  typography: {
    h5: {
      fontSize: "1.2rem",
      fontWeight: "bold",
      color: "#5B524D",
    },
    body1: {
      fontSize: "1rem",
      color: "#5B524D",
    },
  },
});

const valores = ["Confianza", "Responsabilidad", "Innovación", "Integridad", "Calidad"];

const certificaciones = [cer1, esr, Non, KOSHER, IMSS_CAT, IMSS_ELSSA, PNA];

function Presentacion() {
  return (
    <ThemeProvider theme={theme}>
      <Contenedor mt={{ xs: 12, lg: 18 }} mb={0}>
        <Container>
          <Grid container spacing={10} justifyContent="space-between" alignItems="flex-start">
            {/* Misión, Visión, Propósito - Alineado a la izquierda */}
            <Grid item xs={12} sm={12} md={5} lg={6}>
              <TextSection variant="h5" sx={{ color: "#5B524D" }}>
                MISIÓN
              </TextSection>
              <TextSection sx={{ color: "#5B524D" }} variant="body1" textAlign="justify">
                Proveer soluciones e insumos agrícolas, comercializar granos y servicios de calidad;
                generando confianza.
              </TextSection>

              <TextSection sx={{ color: "#5B524D" }} variant="h5" mt={3}>
                VISIÓN
              </TextSection>
              <TextSection sx={{ color: "#5B524D" }} variant="body1" textAlign="justify">
                Ser una corporación de clase mundial, sólida y solvente con negocios diversificados,
                que nos permita consolidarnos como punto de referencia por nuestra profesionalidad y
                calidad certificada, contribuyendo al desarrollo económico de la región.
              </TextSection>

              <TextSection sx={{ color: "#5B524D" }} variant="h5" mt={3}>
                PROPÓSITO
              </TextSection>
              <TextSection sx={{ color: "#5B524D" }} variant="body1" textAlign="justify">
                Conectar con la tierra y alimentar al mundo, creando valor y confianza.
              </TextSection>
            </Grid>

            {/* Valores - Alineado al centro */}
            <Grid item xs={12} sm={12} md={3} lg={2} textAlign="center">
              <TextSection variant="h5" sx={{ color: "#5B524D" }}>
                VALORES
              </TextSection>
              <Grid container spacing={1} justifyContent="center" mt={2}>
                {valores.map((valor, thing) => (
                  <Grid key={thing.id} item xs={5} sm={4} md={12} lg={12}>
                    <Icontext color="#5B524D" imagen={Confianza} maxWidth="1.5rem" Texto={valor} />
                  </Grid>
                ))}
              </Grid>
            </Grid>

            {/* Certificaciones - Alineado a la derecha */}
            <Grid item xs={12} sm={12} md={4} lg={4} textAlign="center">
              <TextSection variant="h5" sx={{ color: "#5B524D" }}>
                CERTIFICACIONES
              </TextSection>
              <Grid container spacing={2} justifyContent="center" mt={1}>
                {certificaciones.map((cert, thing) => (
                  <Grid key={thing.id} item xs={6} sm={4} md={6} lg={6}>
                    <Contenedor component="img" src={cert} maxWidth="12rem" />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Contenedor>
    </ThemeProvider>
  );
}

export default Presentacion;
